import { defineStore } from 'pinia'
import { ref } from 'vue'
import router from '@/router/index'
import { setProductLine, getProductLine } from "@/utils/cache/local-storage";
import { useSaasStore } from "@/store/modules/saas";
import { useSettingsStore } from "@/store/modules/settings";
import { productLineOptions } from "@/constants/const";
import { result } from 'lodash-es';


// specialUrls 设定固定跳转链接. 
const specialUrls = {
  '/game/edit': '/game/list',
  '/version/client_version/edit': '/version/client_version'
}

export const useProductLineStore = defineStore('product_line', () => {

  const product_line = ref<string>(getProductLine() || 'qy')

  const updateProductLine = (value: string, jump = true) => {
    return new Promise((resolve, reject) => {
      const saasStore = useSaasStore()
      saasStore.clearSaasToken()

      product_line.value = value
      setProductLine(value)

      //更新水印文案
      const productLineName = productLineOptions.find(item => item.value === value)?.label || ''
      //设置
      const settingsStore = useSettingsStore()
      settingsStore.textWatermark = productLineName



      //检查当前页面是否需要进行跳转
      if (jump) {
        const currRoute = router.currentRoute.value
        if (currRoute) {
          let resolved = false;
          Object.keys(specialUrls).forEach(key => {
            let val = specialUrls[key]
            if (currRoute.fullPath.startsWith(key)) {
              //返回游戏列表
              router.push(val)
              resolve('never_reload')
              resolved = true
            }
          })
          if (resolved) {
            return
          }
        }
      }
      resolve('')
    })
  }

  return { product_line, updateProductLine }
});


